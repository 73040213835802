import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LibraryService } from '../functional-modules/library/library.service';

@Component({
    selector: 'pdf',
    templateUrl: './pdf.component.html',
    styleUrls: ['pdf.component.scss']
})
export class PdfComponent implements AfterViewInit {
    
    url = null;

    constructor(public route: ActivatedRoute,
                public libraryService: LibraryService) {
    }

    ngAfterViewInit(): void {
        this.route.params.subscribe((params) => {
            this.loadBook(params.bookId);
        });
    }

    loadBook(id) {
        this.libraryService.getBook(id).subscribe((rez) => {
            this.url = rez.url;
        });
    }

}
