import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {RootService} from '../../services/root.service';
import {MyCabinetService} from '../../../functional-modules/my-cabinet/my-cabinet.service';
import {PupilModel} from '../../models/users/pupil.model';
import {ILessonJournal} from '../../interfaces/journal/lesson-journal.interface';
import {IDropdownData} from '../../../functional-modules/classes/_partial/interfaces/dropdown-data.interface';

import * as _ from 'lodash';
import * as moment from 'moment';
import {of, Subscription, zip} from 'rxjs';
import { concatMap, mapTo, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {LibraryService} from '../../../functional-modules/library/library.service';
import {AuthService} from '../../services/auth.service';
import {LessonContentComponent} from '../lesson-content/lesson-content.component';
import {IClassHomeworks} from '../../interfaces/journal/classHomeworks.interface';
import {HelperService} from '../../services/helper.service';

export interface IScheduleLesson {
  class_object: {
    class_id: number;
    id: number,
    type: string,
    class_name: string,
    grade: {
      id: number,
      name: string
    }
  };
  end_time: string;
  location: {
    name: string
  };
  schedule_object: {
    icon_url: string;
    id: number,
    type: string,
    name: string
  };
  seconds_to_end: number;
  seconds_to_start: number;
  start_time: string;
}

export interface IScheduleData {
  lesson_planning: number;
  lesson_planning_author: string;
  date: string;
  journal: ILessonJournal;
  next_schedule: string;
  prev_schedule: string;
  lesson_num: number;
  users: PupilModel[];
  parallel_groups: any[];
  isOpen: boolean;
}

@Component({
  selector: ' journal-lesson',
  templateUrl: './journal-lesson.component.html',
  styleUrls: ['./journal-lesson.component.scss', '../../style/modules-shared.scss', '../tabs/tabs.component.scss']
})
export class JournalLessonComponent implements OnInit, OnDestroy {
  activeTab: string;
  schedule_id: number;
  scheduleLesson: IScheduleLesson;
  scheduleData: IScheduleData;
  isMiddleSchool: boolean;
  middleScoreList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  selectedIndex: number;
  selectedScore: number;
  selectedStudentUuid: string;
  nextLesson: string;
  prevLesson: string;
  hasJournalPermission: boolean;
  isOpen: boolean;
  userUuid = window.localStorage.getItem('uuid');
  lessonOpenedFrom = window.localStorage.getItem('open-lesson-from');
  reqSubs: Subscription;
  timeToLessonEnd: number;
  homeworksToVerify: IClassHomeworks;
  controlWorksToVerify: IClassHomeworks;

  closing: boolean;
  loaded: boolean;
  work = true;
  mark = new FormControl('');
  isExtended: boolean;
  mark_data: any;
  noHomeworks: boolean;
  noControlWork: boolean;

  allScoreTypesDrop: IDropdownData<number>[] = [];
  defaultMarksDrop: IDropdownData<number>[] = [];
  wholeGroupEditMarksDrop: IDropdownData<number>[] = [];
  allScoreTypes: any;
  checkboxList = {};
  pageCheckbox = 0;
  saveMaterials: boolean;
  lessonsGroups: any = [];

  addThemeForm: FormGroup;
  wasSubmitted = false;
  isAuthUserCpAuthor = false;
  addThemeRequiredField = HelperService.formErrors.requiredField;
  tabs = [];
  isControlWork = false;
  theme = null;
  homeWorksList = [];
  themeHomeWorks = [];
  player: YT.Player;

  @ViewChild(LessonContentComponent)
  lessonContentComponent: LessonContentComponent;

  @ViewChild('markPopup') markPopup: ElementRef;
  @Input() saveMaterialChanges = false;
  canBecomeCW = false;

  constructor(private classesService: ClassesService,
              public authService: AuthService,
              public ngxSmartModalService: NgxSmartModalService,
              public rootService: RootService,
              public myCabinetService: MyCabinetService,
              private libraryService: LibraryService,
              public router: Router,
              public route: ActivatedRoute,
              public fb: FormBuilder) {
    // router.events.subscribe((e) => {
    //   console.log(e);
    // });
  }

  ngOnInit(): void {
    this.load();
    this.scoresUpdate();
    this.studentAbsence();
  }

  ngOnDestroy() {
    this.work = false;
    if (this.reqSubs) {
      this.reqSubs.unsubscribe();
    }
  }


  load() {
    this.closing = false;
    this.loaded = false;
    this.schedule_id = null;
    this.checkboxList = {};
    this.isExtended = true;
    this.myCabinetService.openLesson = false;

    this.wasSubmitted = false;
    this.addThemeForm = this.fb.group({
      theme: ['', Validators.required],
      content: [''],
    });

    this.reqSubs = this.route.params.pipe(
      switchMap((param) => {
        this.checkboxList = {};
        this.schedule_id = +param.id;
        this.loaded = false;

        return this.classesService.getJournalDataByScheduleID(this.schedule_id)
          .pipe(
            switchMap((schedule_data) => {
              this.scheduleData = schedule_data;
              this.lessonsGroups = [];
;
              if (this.scheduleData.parallel_groups) {
                this.lessonsGroups = this.scheduleData.parallel_groups.map(({id, group_id, group_name}) => {
                  return {
                    id: String(id),
                    group_id: String(group_id),
                    text: group_name
                  };
                });
              }

              /* sort user scores array as users */
              if (this.scheduleData.journal && this.scheduleData.journal.users) {
                const sort = this.scheduleData.users.map(user => user.uuid);
                this.scheduleData.journal.users.sort((a, b) => {
                  return sort.indexOf(a.uuid) - sort.indexOf(b.uuid);
                });
              }
              return this.classesService.getStudentScheduleLessonData(this.userUuid, this.schedule_id)
                .pipe(switchMap((lesson_data: IScheduleLesson) => {
                  this.scheduleLesson = lesson_data;
                  this.canBecomeCW = this.canAssignCW();
                  this.timeToLessonEnd = Math.round(this.scheduleLesson.seconds_to_end / 60);
                  this.isMiddleSchool = +this.scheduleLesson.class_object.grade.name > 4;
                  this.route.snapshot.queryParams.page ? this.activeTab = this.route.snapshot.queryParams.page : this.activeTab = '1';

                  /* Check author */
                  this.checkPlanningOwner(this.scheduleData.lesson_planning_author);

                  // back timer
                  if (this.timeToLessonEnd > 0) {
                    const backInterval = setInterval(() => {
                      this.timeToLessonEnd -= 1;
                      if (this.timeToLessonEnd === 0) {
                        this.scheduleLesson.seconds_to_end = 0;
                        clearInterval(backInterval);
                      }
                    }, 60000);
                  }
                  if (this.scheduleData.journal && this.scheduleData.journal.theme) {
                    return zip(this.classesService.getScoreTypeByGrade(this.scheduleLesson.class_object.grade.id),
                      this.myCabinetService.getClassHomeworks(this.scheduleData.journal.theme.id,
                        this.scheduleLesson.class_object.type === 'class' ? this.scheduleLesson.class_object.class_id : null
                        , this.scheduleLesson.class_object.type !== 'class' ? this.scheduleLesson.class_object.id : null),
                        this.myCabinetService.getClassControlWorks(this.scheduleData.journal.theme.id,
                            this.scheduleLesson.class_object.type === 'class' ? this.scheduleLesson.class_object.class_id : null
                            , this.scheduleLesson.class_object.type !== 'class' ? this.scheduleLesson.class_object.id : null),
                        this.getThemeContent()
                    );
                  } else {
                    return zip(this.classesService.getScoreTypeByGrade(this.scheduleLesson.class_object.grade.id), of(null),  this.getThemeContent());
                  }
                }));
            }));
      })).pipe(concatMap((rez) => {
      return this.loadHomeWork().pipe(mapTo(rez));
    })).subscribe(([score_types, homeworks, controlWorks]) => {
      this.isControlWork = this.theme && this.theme.is_control;
      this.controlWorksToVerify = controlWorks;
      this.homeworksToVerify = homeworks;
      this.noHomeworks = _.isEmpty(this.homeworksToVerify);
      this.noControlWork = _.isEmpty(this.controlWorksToVerify);

      if (!this.noHomeworks) {
        this.homeworksToVerify.scoreType = score_types.score_types.find(scoreType => scoreType.short_name === 'ДЗ');
        this.homeworksToVerify.users = this.homeworksToVerify.users.map((u) => {
          u.updated_at = this.getNewerUpdate(u.updated_at, this.getStudentControlWork(u.uuid).updated_at);

          return u;
        });
      }

      if (!this.noControlWork) {
        this.controlWorksToVerify.scoreType = score_types.score_types.find(scoreType => scoreType.short_name === 'КР');
      }

      this.allScoreTypes = score_types;
      this.prevLesson = this.scheduleData.prev_schedule;
      this.nextLesson = this.scheduleData.next_schedule;
      this.allScoreTypesDrop = score_types.score_types
        .filter(type => type.short_name !== 'ДЗ' && type.short_name !== 'ВІДП')
        .map(type => {
          return {
            id: type.id,
            text: type.name,
          };
        });
      this.dataForGroupEditDrop();
      this.loaded = true;
      this.canModifyJournalMarks();
    });
  }

  loadHomeWork() {
    const theme = this.scheduleData.journal?.theme?.id;
    const isGroup = this.scheduleLesson.class_object.type === 'class_group';
    let req;

    if (!theme) {
      return of(null);
    }

    if (isGroup) {
      req = this.libraryService.getThemeContentById(theme, null, this.scheduleLesson.class_object.id);
    } else {
      req = this.libraryService.getThemeContentById(theme, this.scheduleLesson.class_object.id, null);
    }

    return req.pipe(tap((rez: any) => {
      this.homeWorksList = [];
      this.themeHomeWorks = rez.materials ? rez.materials.filter(material => material.type === 2) : [];
      this.themeHomeWorks.forEach(homework => {
        this.homeWorksList.push({
          material: [homework.description],
          material_html: [homework.description_html],
          id: [homework.id],
          files: this.getFiles(homework),
          type: 2,
          class_group_list: homework.class_group_list,
          class_list: homework.class_list
        });
      });
    }));
  }

  getFiles(material) {
    return material.files.map(file => {
      return {
        material: file.external_url || file.file_url,
        id: file.id,
        type: file.type,
      };
    });
  }

  toggleControlWork() {
    this.getThemeContent().subscribe(() => {
      this.classesService.updateCurrentLessonTheme({
        theme:  this.theme ? this.theme.name : '',
        content:  this.theme ? this.theme.content : '',
        is_control: this.isControlWork
      }, this.scheduleData.journal.theme.id).subscribe();

      if (this.scheduleData.journal && this.scheduleData.journal.users) {
        if (this.isControlWork) {
          const controlWorkItem = _.find(this.allScoreTypesDrop, ['text', 'Контрольна робота']);
          const controlWordId = controlWorkItem ? controlWorkItem.id : null;

          if (controlWordId) {
            this.addScoreType(controlWordId);
          }
        } else {
          const score_type = _.find(this.scheduleData.journal?.score_types, ['short_name', 'КР']);
          this.groupEditMarks(2, score_type);
        }
      }
    });
  }

  toggleLateness(isChecked, student, journal) {
    this.classesService.updateStudentAttendance({uuid: student.uuid, journal, is_late: isChecked, is_absent: false}).subscribe(() => {
    }, error => {
      if (error.error?.detail) {
        alert(error.error?.detail);
      }
    });
  }

  getThemeContent() {
    const class_object = this.scheduleLesson.class_object;
    let req;

    if (this.isAuthUserCpAuthor) {
      if (this.scheduleData.journal && this.scheduleData.journal.theme) {
        if (class_object.type === 'class_group') {
          req = this.libraryService.getThemeContentById(this.scheduleData.journal.theme.id, null, class_object.id);
        } else {
          req = this.libraryService.getThemeContentById(this.scheduleData.journal.theme.id, class_object.id, null);
        }
      }
    }

    return req ? req.pipe(
        tap((rez => {
            this.theme = rez;
        })
    )) : of(null);
  }

  checkPlanningOwner(author_uuid) {
    this.isAuthUserCpAuthor = author_uuid === this.authService.user.uuid;

    console.log(this.scheduleData);
    if (this.scheduleLesson.schedule_object.id == 108 || this.scheduleLesson.schedule_object.id === 109) {
      this.tabs = [
        {
          path: '1',
          name: 'Навчальний статус учнів'
        }
      ];
      this.activeTab = '1';
    } else {
      this.tabs = [
        {
          path: '1',
          name: 'Навчальні досягнення учнів',
        },
        {
          path: '2',
          name: 'Зміст уроку',
        },
        {
          path: '3',
          name: 'Учнівські роботи',
        }
      ];
    }
  }

  getThemeFormFieldState(field) {
    return this.wasSubmitted ? this.addThemeForm.get(field).valid : null;
  }

  saveNewTheme() {
    this.wasSubmitted = true;
    if (this.addThemeForm.valid) {
      const data = {
        theme: this.addThemeForm.get('theme').value,
        content: this.addThemeForm.get('content').value,
      };
      this.classesService.addCurrentLessonTheme(data, this.schedule_id).subscribe(() => {
        this.ngOnDestroy();
        this.ngOnInit();
      });
    }
  }

  deleteLessonContent() {
    this.ngxSmartModalService.resetModalData('lesson-materials-remove');
    this.ngxSmartModalService.getModal('lesson-materials-remove').open();
  }

  onChangeGroup(groupId) {
    this.router.navigate([`${this.rootService.role.getSectionPath()}/my-cabinet/schedule/${groupId}`]);
  }

  canModifyJournalMarks() {
    this.hasJournalPermission = this.rootService.role.hasPermission('journal_edit');
    return this.hasJournalPermission;
  }

  anotherLesson(type, lesson_to_expand?) {
    if (type === 'next') {
      this.router.navigate([this.myCabinetService.getSectionPath('schedule'), this.nextLesson], {queryParams: {page: this.activeTab}});
    } else if (type === 'prev') {
      this.router.navigate([this.myCabinetService.getSectionPath('schedule'), this.prevLesson], {queryParams: {page: this.activeTab}});

    } else {
      this.isExtended = true;
      this.router.navigate([this.myCabinetService.getSectionPath('schedule'), lesson_to_expand], {queryParams: {page: this.activeTab}});
    }
  }

  toggleAttendance(is_absent, uuid, journal) {
    const attendanceInfo: any = {uuid, journal, is_absent: !is_absent, is_late: false};

    if (is_absent) {
      attendanceInfo.is_late = false;

      this.scheduleData.journal.users.map((user: any) => {
        if (user.uuid === uuid) {
          user.attendance.is_late = false;
        }

        return user;
      });
    }


    this.classesService.updateStudentAttendance(attendanceInfo).subscribe(
      () => this.classesService.updateJournalLessonData$.next(attendanceInfo)
    );
  }

  dataForGroupEditDrop() {
    this.defaultMarksDrop = [];
    this.wholeGroupEditMarksDrop = [];
    const dropdownsData = [
      {
        id: 1,
        text: 'Очистити оцінки та коментарі'
      },
      {
        id: 2,
        text: 'Видалити завдання'
      }];
    dropdownsData.forEach(item => {
      if (item.id !== 2) {
        this.defaultMarksDrop.push({
          id: item.id,
          text: item.text
        });
      }
      this.wholeGroupEditMarksDrop.push({
        id: item.id,
        text: item.text
      });
    });
  }

  groupEditMarks(action, score_type) {
    if (action === 1) {
      const student_uuids = this.scheduleData.users.map(student => student.uuid);
      const dataForSend = {
        uuid_list: student_uuids,
        journal: this.scheduleData.journal.id,
        score_type: score_type.id
      };
      this.classesService.addStudentScore(dataForSend).subscribe(() => {
        this.scheduleData.journal.users
          .map(user => user.scores)
          .flat(1)
          .filter(score => score.score_type === score_type.id)
          .forEach(score => {
            score.score = null;
            score.abbr = null;
            score.teacher_comment = null;
          });
        if (score_type.short_name === 'ДЗ' && this.homeworksToVerify && this.homeworksToVerify.users) {
          this.homeworksToVerify.users.forEach(student => {
            student.score = null;
          });
          this.homeworksToVerify.count_scores = 0;
        }
      }, error => {
        if (error.error?.detail) {
          alert(error.error?.detail);
        }
      });
    } else if (action === 2) {
      const dataForSend = {
        journal: this.scheduleData.journal.id,
        score_type: score_type.id
      };
      this.classesService.deleteScoreColumn(dataForSend).subscribe(() => {
        this.scheduleData.journal.score_types = this.scheduleData.journal.score_types.filter(scoreType => scoreType.id !== score_type.id);
        this.scheduleData.journal.users
          .map(user => user.scores)
          .flat(1)
          .filter(score => score.score_type === score_type.id)
          .forEach(score => {
            score.score = null;
            score.abbr = null;
            score.teacher_comment = null;
          });
        if (score_type.short_name === 'ДЗ' && this.homeworksToVerify && this.homeworksToVerify.users) {
          this.homeworksToVerify.users.forEach(student => {
            student.score = null;
          });
          this.homeworksToVerify.count_scores = 0;
        }
      }, error => {
        console.log(error);
      });
    }
  }

  close() {
    this.activeTab = '1';
    this.work = false;
    this.isExtended = true;
    this.ngxSmartModalService.resetModalData('journal-lesson');
    this.ngxSmartModalService.get('journal-lesson').close();
    window.localStorage.removeItem('achievements-period');
  }

  get isTutorWithoutPermission() {
    console.log(this.rootService.role.isTutor(), this.rootService.role.hasPermission('journal_edit'))
    return this.rootService.role.isTutor() && !this.rootService.role.hasPermission('journal_edit');
  }

  /* asses students */
  updateStudentScore(student, score_type, student_name, student_surname, grade_id, can_add?, date?, can_edit?, student_score?, event?) {
    if (event) {
      event.stopPropagation();
    }

    if (this.isTutorWithoutPermission) {
      return;
    }

    const rez = this.scheduleData.journal.users.find(user => user.uuid === student.uuid);

    this.mark_data = {
      journal: this.scheduleData.journal.id,
      score_type,
      student,
      student_name,
      student_surname,
      grade_id,
      date,
      can_add,
      can_edit,
      student_score,
      has_journal_permission: this.hasJournalPermission,
      is_middle_school: this.isMiddleSchool
    };

    this.ngxSmartModalService.resetModalData('student-assessment');
    this.ngxSmartModalService.getModal('student-assessment').setData({
      mark_data: this.mark_data
    });
    this.ngxSmartModalService.getModal('student-assessment').open();
  }

  assesSeveralStudentsAtOnce(grade_id, can_add) {
    const selected_students = Object.keys(_.pickBy(this.checkboxList));

    this.mark_data = {
      journal: this.scheduleData.journal.id,
      score_types: this.scheduleData.journal.score_types,
      score_type: this.scheduleData.journal.score_types,
      modal_header: 'Оцінювання',
      student_uuid: selected_students,
      grade_id,
      is_middle_school: this.isMiddleSchool,
      can_add,
      is_group_mark: true,
      students: this.scheduleData.journal.users
    };

    this.ngxSmartModalService.resetModalData('student-assessment');
    this.ngxSmartModalService.getModal('student-assessment').setData({
      mark_data: this.mark_data
    });
    this.ngxSmartModalService.getModal('student-assessment').open();
  }

  openMiddleAssesStudentModal(type, student, score_type, student_name?, student_surname?, grade_id?, can_add?, date?, can_edit?, student_score?) {
    this.updateStudentScore(student, score_type, student_name, student_surname, grade_id, can_add, date, can_edit, student_score);
  }

  marksClose(open) {
    if (!open) {
      this.isOpen = false;
    }
  }

  /* select students */

  getSelectedCount() {
    this.pageCheckbox = _.keys(this.checkboxList).filter(key => this.checkboxList[key]).length;
    return this.pageCheckbox;
  }

  selectAllList(state) {
    this.scheduleData.users.forEach(({uuid}) => {
      this.checkboxList[uuid] = !state;
    });
  }

  onChangeCheckbox(value, studentId) {
    this.checkboxList[studentId] = value;
  }

  /* add new score type */

  addScoreType(score_type_id) {
    const isTypeExist = this.scheduleData.journal.score_types ?
      this.scheduleData.journal.score_types.find(item => item.id === score_type_id) : false;
    const newScoreType = this.allScoreTypes.score_types.find(item => item.id === score_type_id);

    if (!isTypeExist && newScoreType) {
      const scoreType = {
        score_type: newScoreType.id,
        journal: this.scheduleData.journal.id
      };
      this.classesService.createScoreColumn(scoreType).subscribe((results: any) => {
        this.scheduleData.journal.score_types.push(newScoreType);

        if (results.default_data) {
          this.scheduleData.journal.users.forEach(user => {
            if (results.default_data[user.uuid]) {
              user.scores.push(results.default_data[user.uuid]);
            }
          });
        }
      });
    }
  }

  expand() {
    const lessonMonth = this.scheduleData.date.split('-')[1];
    this.classesService.getLessonId$.next(this.scheduleLesson.schedule_object.id);
    this.classesService.changePeriodFilter$.next(lessonMonth);
    this.isExtended = false;
  }

  changeTab(tab) {
    this.saveMaterialChanges = null;
    if (this.activeTab === '2' && this.lessonContentComponent
      && (this.lessonContentComponent.updateModeMaterial || this.lessonContentComponent.updateModeHomework)
      && this.lessonContentComponent.hasChanges()) {
      this.ngxSmartModalService.resetModalData('modify-lesson-materials');
      this.ngxSmartModalService.getModal('modify-lesson-materials').setData({
        type: 'save-modified',
        msg: 'Ви внесли зміни в матеріали уроку'
      });
      this.ngxSmartModalService.getModal('modify-lesson-materials').open();

      this.classesService.saveMaterialsPopupAnswer$
        .pipe(
          take(1)
        ).subscribe((answer: boolean) => {
        if (answer) {
          this.saveMaterialChanges = answer;
          this.ngxSmartModalService.getModal('modify-lesson-materials').close();
        } else {
          this.activeTab = tab.path;
          this.router.navigate([], {queryParams: {page: tab.path}});
        }
      });
    } else {
      this.activeTab = tab.path;
      this.router.navigate([this.myCabinetService.getSectionPath('schedule'), this.route.snapshot.params.id], {queryParams: {page: tab.path}});
    }
  }

  changeTheme() {
    const modalInfo = {
      ktp_id: this.scheduleData.journal.lesson_planning,
      theme_id: this.scheduleData.journal.theme.id,
      journal_id: this.scheduleData.journal.id
    };
    this.ngxSmartModalService.resetModalData('another-theme-popup');
    this.ngxSmartModalService.getModal('another-theme-popup').setData(modalInfo);
    this.ngxSmartModalService.getModal('another-theme-popup').open();
  }

  openAssignPlanningPopup(access: 'public' | 'personal') {
    const classTitle = `КТП ${this.scheduleLesson.schedule_object.name} ${this.scheduleLesson.class_object.grade.name} клас`;

    this.ngxSmartModalService.resetModalData('calendar-plannings-popup');

    let groupList = null;
    if (this.scheduleLesson.class_object.type === 'class_group') {
      if (this.lessonsGroups.length > 1) {
        groupList = this.lessonsGroups.map(oneGroup => oneGroup.group_id);
      } else {
        groupList = this.scheduleLesson.class_object.id;
      }
    }

    this.ngxSmartModalService.getModal('calendar-plannings-popup').setData({
      gradeId: this.scheduleLesson.class_object.grade.id,
      lessonId: this.scheduleLesson.schedule_object.id,
      classId: this.scheduleLesson.class_object.class_id,
      groupId: groupList,
      title: access === 'public' ? 'Бібліотека ' + classTitle : classTitle,
      breadcrumbs: [
        {
          link: this.classesService.getSectionPath('/'),
          text: 'Класи',
        },
        {
          text: this.scheduleLesson.class_object.class_name,
        },
        {
          text: 'Журнал',
          link: `${this.classesService.getSectionPath(`${this.scheduleLesson.class_object.id}/journal`)}`
        },
        {
          text: this.scheduleLesson.schedule_object.name,
        }
      ],
      access,
      onSave: () => {
        // this.classesService.updateJournalLessonData$.next(null);
        this.load();
      }
    });
    this.ngxSmartModalService.getModal('calendar-plannings-popup').open();
  }

  editCP() {
    const editData: any = {
      is_public: true,
      title: 'Редагування календарно-тематичне планування',
      type: 'edit',
      teacherUuid: this.rootService.role.isTeacher() ? this.authService.user.uuid : null,
      classId: this.scheduleLesson.class_object.id,
      id: this.scheduleData.lesson_planning,
      step: 2,
      onSave: () => {
        this.load();
      }
    };

    if (this.scheduleLesson.class_object.type === 'class_group') {
      editData.classGroup = this.scheduleLesson.class_object.id;
      editData.classId = this.scheduleLesson.class_object.class_id;
    }

    this.ngxSmartModalService.resetModalData('calendar-planning-popup');
    this.ngxSmartModalService.getModal('calendar-planning-popup').setData(editData);
    this.ngxSmartModalService.getModal('calendar-planning-popup').open();
  }

  editLessonContent(ktp_id) {
    this.router.navigate([this.myCabinetService.getSectionPath(`calendar-planning/${ktp_id}`)]);
  }

  deleteAllMaterials() {
    const reqData = {
      lesson_planning_theme: this.scheduleData.journal.theme.id
    };
    this.libraryService.deleteAllFilesFromLessonTheme(reqData).subscribe(response => {
      this.load();
      // this.classesService.updateJournalLessonData$.next(response);
    });
  }

  scoresUpdate() {
    this.classesService.updateJournalLessonData$
      .pipe(takeWhile(() => this.work))
      .subscribe(respData => {
        if (respData && (respData.score || respData.teacher_comment || typeof respData === 'number' || respData.file_url) && this.mark_data) {
          /* group assessment */
          if (respData.uuid_list && Array.isArray(respData.uuid_list) && respData.uuid_list.length) {
            this.load();
            return;
          }

          const student = this.scheduleData.journal.users.find(user => user.uuid === this.mark_data.student.uuid);
          let hwStudent;
          let cwStudent;

          this.homeworksToVerify ? hwStudent = this.homeworksToVerify.users.find(user => user.uuid === this.mark_data.student.uuid) :
            hwStudent = null;
          this.controlWorksToVerify ? cwStudent = this.controlWorksToVerify.users.find(user => user.uuid === this.mark_data.student.uuid) :
              cwStudent = null;

          if (respData.score || respData.teacher_comment || respData.file_url) {

            /* one student assessment */
            if (respData.score_type && respData.score_type.id && !this.hasSuchScore(student.scores, respData)) {
              respData.score_type = respData.score_type.id;
              /* create new score */
              student.scores.push(respData);
              if (this.mark_data.score_type.short_name === 'ДЗ' && hwStudent) {
                hwStudent.score = {
                  created_at: respData.created_at,
                  id: respData.id,
                  score: respData.score,
                  teacher_comment: respData.teacher_comment,
                  file_url: respData.file_url
                };
                this.homeworksToVerify.count_scores++;
              } else if (this.mark_data.score_type.short_name === 'КР' && cwStudent) {
                cwStudent.score = {
                  created_at: respData.created_at,
                  id: respData.id,
                  score: respData.score,
                  teacher_comment: respData.teacher_comment,
                  file_url: respData.file_url
                };
                this.controlWorksToVerify.count_scores++;
              }
            } else {
              /* edit score */
              const editedScore = student.scores.find(score => score.score_type === this.mark_data.score_type.id);

              if (this.mark_data.score_type.short_name === 'ДЗ' && hwStudent) {
                hwStudent.score = {
                  created_at: respData.created_at,
                  id: respData.id,
                  score: respData.score,
                  teacher_comment: respData.teacher_comment,
                  file_url: respData.file_url
                };
              } else if (this.mark_data.score_type.short_name === 'КР' && cwStudent) {
                cwStudent.score = {
                  created_at: respData.created_at,
                  id: respData.id,
                  score: respData.score,
                  teacher_comment: respData.teacher_comment,
                  file_url: respData.file_url
                };
              }

              if (this.isMiddleSchool) {
                editedScore.abbr = respData ? respData.abbr : null;
                editedScore.score = respData.score;
                editedScore.teacher_comment = respData.teacher_comment;
                editedScore.file_url = respData.file_url;
              } else {
                editedScore.abbr = respData ? respData.abbr : null;
                editedScore.score = respData.score;
                editedScore.teacher_comment = respData.teacher_comment;
                editedScore.file_url = respData.file_url;
              }
            }
          } else if (typeof respData === 'number') {
            /* delete score */

            const editedScoreIndex = student.scores.findIndex(score => score.id === respData);
            student.scores.splice(editedScoreIndex, 1);
            if (this.mark_data.score_type.short_name === 'ДЗ' && hwStudent) {
              hwStudent.score = null;
              this.homeworksToVerify.count_scores--;
            } else if (this.mark_data.score_type.short_name === 'КР' && cwStudent) {
              cwStudent.score = null;
              this.controlWorksToVerify.count_scores--;
            }
          }
        }
      });
  }

  studentAbsence() {
    this.classesService.updateJournalLessonData$
      .pipe(takeWhile(() => this.work))
      .subscribe(respData => {
        if (respData && this.scheduleData && _.isBoolean(respData.is_absent)) {
          const student = this.scheduleData.journal.users.find(user => user.uuid === respData.uuid);
          student.attendance.is_absent = respData.is_absent;
        }
      });
  }

  hasSuchScore(user_scores, respData) {
    if (respData.score_type) {
      return !!user_scores.find(score => score.score_type === respData.score_type.id);
    } else {
      return false;
    }
  }

  getStudentScore(studentScores: any, scoreType: any) {
    return [studentScores.find(score => score.score_type === scoreType.id)];
  }

  navigateToStudentHW(student) {
    this.router.navigate([`theme/${this.scheduleData.journal.theme.id}/student/${student.uuid}`], {relativeTo: this.route});
  }

  getFilesArray(files, type) {
    return files.filter(file => file.type === type);
  }

  getStudentControlWork(uuid) {
    return _.find(this.controlWorksToVerify.users, {uuid});
  }

  getScoresTypes(types) {
    if (!types) {
      return [];
    }
    
    return types.sort((a, b) => {
      return a.priority - b.priority;
    });
  }

  openMaterialsPopup(task) {
    const files = this.themeHomeWorks.map(tasks => tasks.files)
        .flat(1)
        .filter(fileInfo => this.isImage(fileInfo.file_url))
        .map(fileInfo => fileInfo.file_url);

    this.ngxSmartModalService.resetModalData('homeworks-popup');
    this.ngxSmartModalService.getModal('homeworks-popup').setData(
        {
          role: 'teacher',
          photos: files,
          selected_photo: task.material,
          showHomeworkButtons: true,
          isControl: false
        }
    );
    this.ngxSmartModalService.getModal('homeworks-popup').open();
  }

  getNewerUpdate(date1, date2) {
    if (date1 && date2) {
      const [fd, fm, fy] = date1.split(' ')[0].split('.');
      const [ld, lm, ly] = date2.split(' ')[0].split('.');

      const fDate = new Date();

      fDate.setDate(fd);
      fDate.setMonth(fm);
      fDate.setFullYear(fy);

      const lDate = new Date();

      lDate.setDate(ld);
      lDate.setMonth(lm);
      lDate.setFullYear(ly);

      return fDate.getTime() > lDate.getTime() ? date1 : date2;
    }

    return date1 || date2;
  }

  savePlayer(player) {
    this.player = player;
  }

  isImage(url) {
    if (url) {
      const ext = url.split(/[#?]/)[0].split('.').pop().trim();
      return ['jpg', 'jpeg', 'gif', 'png'].indexOf(ext.toLowerCase()) !== -1;
    }
  }

  canAssignCW() {
    const lessonDate = moment(new Date(`${this.scheduleData.date} ${this.scheduleLesson.start_time}`));
    const currDate = moment();
    const duration = moment.duration(lessonDate.diff(currDate));

    return duration.asHours() <= 3;
  }

  notScoreTypes() {
    return this.allScoreTypesDrop.filter((score) => {
      return !_.find(this.scheduleData.journal.score_types, ['score_type', score.id]);
    });
  }

  get lessonGroupId() {
    return this.route.snapshot.params.id;
  }

  get isTutor() {
    return this.rootService.role.isTutor();
  }

  get className() {
    const grade = this.scheduleLesson.class_object.grade.name;

    return `${/\d/.test(grade) ? grade + '-й' : grade} ${this.scheduleLesson.class_object.class_name}`;
  }
}
