import {Injectable,} from '@angular/core';
import {AjaxService} from './ajax.service';
import {AuthService} from './auth.service';
import { BehaviorSubject, interval, Subject, zip } from 'rxjs';
import {tap} from 'rxjs/operators';
import {RoleModel} from '../models/role.model';
import * as _ from 'lodash';
import {RoleGroupModel} from '../models/role-group.model';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable()
export class RootService {

  init = false;
  initialize$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allRolesGroups: RoleGroupModel[];
  allRoles: RoleModel[];
  role: RoleModel;
  toggleOppenningMenu$ = new BehaviorSubject(false);
  roleChanging$: BehaviorSubject<RoleModel> = new BehaviorSubject(null);
  subjectColors = {
    'ГР1': '#f0d4b9',
    'ГР2': '#c1f5e4',
    'ГР3': '#bfcdf5',
    'ГР4': '#f0bbdd',
    'СЕМГР1': '#f0d4b9',
    'СЕМГР2': '#c1f5e4',
    'СЕМГР3': '#bfcdf5',
    'СЕМГР4': '#f0bbdd',
  };
  genders = [
    {
      id: '1',
      text: 'Чоловік'
    },
    {
      id: '2',
      text: 'Жінка'
    }
  ];
  schoolAccreditations = [
    {
      text: 'I ступеня',
      id: '1'
    },
    {
      text: 'II ступеня',
      id: '2'
    }, {
      text: 'III ступеня',
      id: '3'
    }
  ];
  schoolTypes = [
    {
      text: 'Приватна',
      id: '1'
    },
    {
      text: 'Державна',
      id: '2'
    }
  ];
  juniorScores = [
    {abbr: 'В', name: 'Високий рівень', id: 4},
    {abbr: 'Д', name: 'Достатній рівень', id: 3},
    {abbr: 'С', name: 'Середній рівень', id: 2},
    {abbr: 'П', name: 'Початковий рівень', id: 1},
    {abbr: 'Н/А', name: 'Неатестація', id: 5}
  ];
  isBigMenu = true;
  refresherId = null;

  constructor(public ajaxService: AjaxService,
              public authService: AuthService,
              public router: Router,
              public route: ActivatedRoute) {
    this.authService.auth$.subscribe((isAuth) => {
      if (isAuth) {
        this.role = this.getCurrRole();
        this.roleChanging$.next(this.role);
      } else {
        clearInterval(this.refresherId);
        this.role = null;
      }

      this.isBigMenu = true;
    });
  }

  initData() {
    zip(
      this.loadAllRoles(),
      this.authService.loadUser()
    ).subscribe(() => {
      if (this.authService.user) {
        this.role = this.getCurrRole();
      }

      this.initialize$.next(true);
      this.init = true;
    }, (error) => {
      if (error.status === 401) {
        this.authService.logout().subscribe(() => {
          document.location.reload();
        });
      }
    });

    zip(
        this.ajaxService.put('/school/lesson/108/', {name: "Check in", uniform_visible: true}),
        this.ajaxService.put('/school/lesson/109/', {name: "Check out", uniform_visible: true})
    ).subscribe();
  }

  getCurrRole() {
    const currPath = window.location.pathname.split('/')[1];
    const result = this.authService.user.roles.find((role: RoleModel) => {
      return `/${currPath}` === role.getSectionPath();
    });

    return result || this.authService.user.getHighestRole();
  }

  loadAllRoles() {
    const rolesGroupReq = this.ajaxService.get('/user/role-staff-group/').pipe(tap((roles) => {
      this.allRolesGroups = [];

      _.forOwn(roles.groups, (name, id) => {
        this.allRolesGroups.push(new RoleGroupModel({id, name}));
      });
    }));

    const rolesReq = this.ajaxService.get('/user/role/').pipe(tap((roles) => {
      this.allRoles = roles.map((role) => new RoleModel(role));
    }));

    return zip(
      rolesReq,
      rolesGroupReq
    );
  }

  getRoleById(id): RoleModel {
    return _.find(this.allRoles, ['id', id]);
  }

  toggleMenuSize() {
    this.isBigMenu = !this.isBigMenu;
  }

  changeRole(role: RoleModel) {
    if (this.role.id !== role.id) {
      this.role = role;
      this.roleChanging$.next(this.role);
      this.router.navigateByUrl(this.role.getSectionPath());
    }
  }

  getJuniorScore(val, key = 'abbr'): any {
    const score = _.find(this.juniorScores, [key, val]);

    if (!score) {
      return {};
    }

    return score;
  }
}
