import {Injectable,} from '@angular/core';
import {AjaxService} from '../../+shared/services/ajax.service';
import {RootService} from '../../+shared/services/root.service';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as _ from 'lodash';
import { AuthService } from '../../+shared/services/auth.service';
import { ClassesService } from '../classes/classes.service';
import * as moment from 'moment';
import { ParentsService } from '../../parents/parents.service';

@Injectable()
export class MessengerService {

    tabs = [];
    loadUsers$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    users = null;
    savedUsers = null;
    classes = [];
    loaded = false;
    classId = null;
    childClassId = null;
    chats = null;

    constructor(public ajaxService: AjaxService,
                public rootService: RootService,
                public authService: AuthService,
                public classesService: ClassesService,
                public ngxSmartModalService: NgxSmartModalService,
                public parentsService: ParentsService) {
        this.rootService.roleChanging$.pipe(
            filter(role => !!role)
        ).subscribe(() => {
            this.getTabs();

            if (this.rootService.role.isParent()) {
                this.parentsService.getChildren(this.authService.user.uuid).subscribe((rez) => {
                    rez.forEach(child => {
                        this.tabs.push({
                            path: this.getSectionPath('list/teachers/' + child.user_class.id),
                            name: `Вчителі (${child.user.first_name})`,
                        });
                    });

                    console.log(this.tabs);
                });
            }
        });
    }

    getTabs() {
        if (this.rootService.role.isParent() || this.rootService.role.isStudent()) {
            if (this.rootService.role.isParent()) {
                if (!this.tabs.length) {
                    this.tabs = [{
                        path: this.getSectionPath('list/chats'),
                        name: 'Чати',
                    }];
                }
            } else {
                this.tabs = [
                        {
                            path: this.getSectionPath('list/chats'),
                            name: 'Чати',
                        },
                        {
                            path: this.getSectionPath('list/teachers'),
                            name: 'Вчителі',
                        }
                        ];
            }

        } else if (this.rootService.role.isTeacher()) {
            this.tabs = [
                {
                    path: this.getSectionPath('list/chats'),
                    name: 'Чати',
                },
                {
                    path: this.getSectionPath('list/parents'),
                    name: 'Батьки',
                },
                {
                    path: this.getSectionPath('list/pupils'),
                    name: 'Учні',
                }
            ];
        }

        return this.tabs;
    }

    getSectionPath(path) {
        const section = `${this.rootService.role.getSectionPath()}/messenger`;
        return `${section}/${path}`;
    }

    getUsers(refresh = false) {
        if (!refresh) {
            this.loaded = false;
        }

        return this.loadClasses().pipe(
            switchMap(() => {
                let req1;
                let req2;

                if (this.rootService.role && this.rootService.role.isParent()) {
                    req1 = this.ajaxService.get('/student/parent-teacher-comment/users/', {class_id: this.childClassId});
                } else if (this.rootService.role && this.rootService.role.isTeacher()) {
                    if (!this.classId) {
                        this.classId = '' + this.classes[0].id;
                    }

                    req1 = this.ajaxService.get('/student/parent-teacher-comment/users/', {class_id: this.classId});
                } else {
                    req1 = this.ajaxService.get('/student/parent-teacher-comment/users/');
                }

                req2 = this.getChats();

                return zip(req1, req2);
            })
        ).pipe(tap(([rez1, rez2]: any) => {
            this.users = rez1;
            this.chats = rez2;
            this.savedUsers = _.cloneDeep(rez1);
            this.savedUsers.chats =  _.cloneDeep(rez2.users as any[]);
            this.setUnreadCount();
            this.loaded = true;
            this.loadUsers$.next(true);
        }));
    }

    setUnreadCount() {
       this.tabs = this.tabs.map(tab => {
               let usersArray;

               switch (tab.name) {
                   case 'Батьки':
                       usersArray = this.users.parents;
                       break;
                   case 'Учні':
                       usersArray = this.users.students;
                       break;
                   case 'Вчителі':
                       usersArray = this.users.teachers;
                       break;
                   case 'Чати':
                       usersArray = this.chats.users;
                       break;
                   default:
                       usersArray = this.users.teachers;
                       break;
               }

               tab.count = 0;

               usersArray.forEach(u => {
                   if (u.unreaded_comments_from_count) {
                       tab.count += (+u.unreaded_comments_from_count);
                   }
               });

               return tab;
       });
    }

    getChats() {
        return this.ajaxService.get('/student/parent-teacher-comment/chats/').pipe(map((rez) => {
            rez.chats = rez.chats.map((chat) => {
                const localTime = moment(chat.comment.created_at);

                chat.comment.formattedDate = moment().isSame(localTime, 'day') ? localTime.format('HH:mm') : localTime.format('D MMMM');

                return chat;
            });

            return rez;
        }));
    }

    getChat(uuid) {
        return this.ajaxService.get('/student/parent-teacher-comment/', {uuid});
    }
    
    removeMessage(id) {
        return this.ajaxService.delete(`/student/parent-teacher-comment/remove/${id}/`)
    }

    addNewMessage(data) {
        return this.ajaxService.post('/student/parent-teacher-comment/?uuid=' + data.uuid, data);
    }

    loadClasses(): Observable<any> {
        if (this.rootService.role.isTeacher()) {
            return this.ajaxService.get('/student/parent-teacher-comment/teacher-classes/').pipe(tap((classes) => {
                this.classes = classes.map((i) => {
                    return {
                        id: String(i.id),
                        text: /\d/.test(i.grade.name) ? `${i.grade.name}-й ${i.name} (${i.grade.education_group})` : `${i.grade.name} ${i.name} (${i.grade.education_group})`
                    };
                });
            }));
        }

        return of(null);
    }

    searchUsers(type, searchText) {
        if (searchText) {
            if (type !== 'chats') {
                this.users[type] = this.users[type].filter(u => {
                    return `${u.username}`.toLowerCase().includes(searchText.toLowerCase());
                });
            } else {
                this.chats.users = this.chats.users.filter(u => {
                    return `${u.username}`.toLowerCase().includes(searchText.toLowerCase());
                });
            }
        } else {
            if (type !== 'chats') {
                this.users[type] = _.cloneDeep(this.savedUsers[type]);
            } else {
                this.chats.users = _.cloneDeep(this.savedUsers[type]);
            }
        }
    }

    getReadStatus() {
        return this.ajaxService.get('/student/parent-teacher-comment/unreaded-comments/');
    }
}


