import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ClassesService} from '../../../functional-modules/classes/classes.service';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, Subscription, zip} from 'rxjs';
import {switchMap, takeWhile, tap} from 'rxjs/operators';
import {PupilModel} from '../../models/users/pupil.model';
import {ILessonJournal} from '../../interfaces/journal/lesson-journal.interface';
import {IDropdownData} from '../../../functional-modules/classes/_partial/interfaces/dropdown-data.interface';
import * as _ from 'lodash';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ManagementService} from '../../../functional-modules/management/management.service';
import { RootService } from '../../services/root.service';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss', '../../style/modules-shared.scss']
})
export class AchievementsComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  sub: Subscription;
  classId: string;
  lessonId: string;
  periodId: string;
  periodID = +window.localStorage.getItem('achievements-period');
  classGroupId: string;
  can_add_score: boolean;
  isError: boolean = false;
  colors = {
    'ГР1': '#f0ab67',
    'ГР2': '#67f0c4',
    'ГР3': '#7a9bf5',
    'ГР4': '#f57aca',
  };

  classStudents: PupilModel[];
  journalData: ILessonJournal[];
  currentMonth: string;
  selectedMonth: string;
  classStudentsFiltered: PupilModel[];
  journalDataFiltered: ILessonJournal[];
  loaded: boolean = false;
  tableLoaded = false;

  mark = new FormControl('');
  selectedIndex: number;
  isMiddleSchool: boolean;
  middleScoreList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  classGrade: number;
  classGradeId: number;

  pupils: IDropdownData<any>[];
  tasks: IDropdownData<any>[];
  groupEditMarksDrop: IDropdownData<any>[] = [];
  wholeGroupEditMarksDrop: IDropdownData<any>[] = [];
  ansHomeworkEditMarksDrop: IDropdownData<any>[] = [];

  allScoreTypesDrop: IDropdownData<number>[] = [];
  allScoreTypes: any;

  periods: {
    id: number,
    text: string
  }[];

  studentsFilter = [];
  taskTypesFilter = [];
  periodsFilter: number;
  isOpen = false;
  work = true;
  form: FormGroup;
  periodFilterChanges: any;
  tasksFilterChanges: any;
  studentsFilterChanges: any;
  selDate: string;

  checkboxList = {};
  pageCheckbox = 0;
  mark_data: any;
  journalType = window.localStorage.getItem('view-journal-mode');
  rewards: {
    month_list: {
      id: number,
      name: string,
    }[],
    users: {
      first_name: string,
      gender: string,
      last_name: string,
      photo: string,
      rewards: {
        month: number,
        amount: number,
        lesson: any
      }[]
      surname: string,
      username: string,
      uuid: string
    }[]
  };
  today = new Date();
  visitingEditingId;
  currClass: any = null;

  @Input() isExpandable: false;
  @Input() simpleClass: {
    class_id?: number;
    class_name: string
    grade: {
      id: number,
      name: string
    }
    group_name?: string
    id: number
    type: string
  };
  @Input() chosenDate: string;
  @Output() expandScheduleDate: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('markPopup') markPopup: ElementRef;

  constructor(private classesService: ClassesService,
              public route: ActivatedRoute,
              public router: Router,
              public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public changeDetectorRef: ChangeDetectorRef,
              public fb: FormBuilder,
              private eRef: ElementRef,
              public rootService: RootService) {
  }

  ngOnInit(): void {
    this.loadData();
    this.scoresUpdate();

    if (!this.isExpandable) {
      this.classesService.lesson$
          .pipe(
              takeWhile(() => this.work),
              filter(classObj => !!classObj))
          .subscribe((classObj) => {
            this.currClass = classObj;
          });
    } else {
      this.currClass = this.simpleClass;
    }
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  loadData() {
    this.getSelDate();
    this.checkboxList = {};
    this.periods = this.classesService.periods;
    const classIdSub = this.classesService.getClassById$;
    const lessonIdSub = this.classesService.getLessonId$;
    const periodChangeSub = this.classesService.changePeriodFilter$;
    const studentsChangeSub = this.classesService.changeStudentsFilter$;
    const tasksChangeSub = this.classesService.changeTaskTypesFilter$;
    this.sub = combineLatest([classIdSub, lessonIdSub, periodChangeSub, studentsChangeSub, tasksChangeSub])
      .pipe(
        takeWhile(() => this.work),
        tap(([class_id, lesson_id, period_id]) => {
          this.lessonId = lesson_id;
          this.periodId = period_id;
          if (this.simpleClass) {
            if (this.simpleClass.type === 'class') {
              this.classId = String(this.simpleClass.id);
            } else {
              this.classGroupId = String(this.simpleClass.id);
            }
            this.classGrade = +this.simpleClass.grade.name;
            this.classId = String(this.simpleClass.class_id);
            this.classGradeId = this.simpleClass.grade.id;
          } else {

            this.classId = class_id.id;
            this.classGroupId = this.route.snapshot.queryParams.class_group_id;
            this.classGrade = +this.classesService.class.grade.name;
            this.classGradeId = this.classesService.class.grade.id;
          }


        }),
        switchMap((data) => {
            if (this.simpleClass) {
              if (this.simpleClass.type === 'class') {
                return zip(this.classesService.getAllLessonsBySubject(this.lessonId, this.classId, this.periodId, this.classGroupId),
                  this.classesService.getScoreTypeByGrade(this.simpleClass.grade.id),
                  this.classesService.getClassRewards(this.classId, this.lessonId));
              } else {
                return zip(this.classesService.getAllLessonsBySubject(this.lessonId, this.classId, this.periodId, this.classGroupId),
                  this.classesService.getScoreTypeByGrade(this.simpleClass.grade.id),
                  this.classesService.getClassGroupRewards(this.classGroupId));
              }
            } else {
              if (this.classGroupId) {
                return zip(this.classesService.getAllLessonsBySubject(this.lessonId, this.classId, this.periodId, this.classGroupId),
                  this.classesService.getScoreTypeByGrade(+this.classesService.class.grade.id),
                  this.classesService.getClassGroupRewards(this.classGroupId)
                );
              } else {
                return zip(this.classesService.getAllLessonsBySubject(this.lessonId, this.classId, this.periodId, this.classGroupId),
                  this.classesService.getScoreTypeByGrade(+this.classesService.class.grade.id),
                  this.classesService.getClassRewards(this.classId, this.lessonId));
              }
            }
          }
        ))
      .subscribe(([journalData, scoreTypes, studentRewards]) => {
        this.rewards = studentRewards;
        this.allScoreTypes = scoreTypes;
        this.classStudents = journalData.users;
        this.journalData = journalData.journal_list.map((i) => {
          i.timestamp = new Date(i.date);

          return i;
        });
        this.currentMonth = String((new Date).getMonth() + 1);
        this.selectedMonth = journalData.filter_month;
        if (!this.journalType) {
          window.localStorage.setItem('view-journal-mode', 'scores');
          this.journalType = window.localStorage.getItem('view-journal-mode');
        }

        this.classGrade < 5 ? this.isMiddleSchool = false : this.isMiddleSchool = true;
        this.allScoreTypesDrop = scoreTypes.score_types
          .filter(type => type.short_name !== 'ДЗ' && type.short_name !== 'ВІДП')
          .filter(type =>  {
              return type.short_name !== 'РЛТ' || type.short_name === 'РЛТ' && ([1, 2, 29, 52].includes(+this.lessonId));
          }).map(type => {
            return {
              id: type.id,
              text: type.name,
            };
          });

        this.initFilters();
        this.filter();
        this.dataForStudentsFilter();
        this.dataForTaskTypesFilter();
        this.dataForTaskTypesFilter();
        this.dataForGroupEditDrop();

        this.loaded = true;
        this.tableLoaded = true;
        this.isError = false;
      }, err => {
        if (err.status === 403) {
          this.loaded = true;
          this.isError = true;

        } else {
          alert('Server error');
        }
      });
  }

  getSelDate() {
    if (this.chosenDate) {
      this.selDate = this.chosenDate;
    } else {
      this.selDate = this.today.getFullYear() + '-' + ((this.today.getMonth() + 1) < 10 ?
        '0' + (this.today.getMonth() + 1) : (this.today.getMonth() + 1)) + '-' + (this.today.getDate() < 10 ?
        '0' + this.today.getDate() : this.today.getDate());
    }
  }

  initFilters() {
    if (!window.localStorage.getItem('achievements-period')) {
      this.isExpandable ? window.localStorage.setItem('achievements-period', this.selectedMonth) :
        window.localStorage.setItem('achievements-period', this.currentMonth);
    }
    this.periodsFilter = +window.localStorage.getItem('achievements-period');
    if (window.localStorage.getItem('achievements-students')) {
      this.studentsFilter = window.localStorage.getItem('achievements-students').split(',');
    }
    if (window.localStorage.getItem('achievements-tasks')) {
      this.taskTypesFilter = window.localStorage.getItem('achievements-tasks').split(',');
    }
  }

  checkDates(lessonData, score_types) {
    const curDate = new Date();
    const lessonDate = new Date(lessonData);

    if (+this.periodID && score_types) {
      return !(!score_types.length || !score_types);
    } else if (!+this.periodID && score_types) {
      return !(!score_types.length || !score_types);
    } else {
      return lessonDate <= curDate;
    }
  }

  expandDate(scheduleId) {
    this.expandScheduleDate.emit(scheduleId);
  }

  totalCount(student) {
    if (this.journalType === 'scores') {
      const scores = this.journalDataFiltered
        .filter(simpleLesson => simpleLesson.score_types)
        .map(simpleLesson => simpleLesson.users).flat()
        .filter(user => user.uuid === student.uuid)
        .filter(studentScores => studentScores.scores.length).flat()
        .map(studentScores => studentScores.scores).flat();
      
      const withMarks = scores.filter(score => score.score && score.abbr !== 'Н/А');
      const totalMarks = withMarks.filter(score => score.score && score.abbr !== 'Н/А').map(score => score.score).reduce((sum, current) => sum + current, 0);
      const averageMark = (totalMarks / withMarks.length).toFixed(1);

      if (this.isMiddleSchool) {
        return isNaN(+averageMark) ? '' : averageMark;
      } else {
        if (isNaN(+averageMark)) {
          return '';
        } else {
          if (+averageMark >= 3.4) {
            return'В';
          } else if (+averageMark >= 2.4) {
            return 'Д';
          } else if (+averageMark >= 1.6) {
            return 'С';
          } else {
            return 'П';
          }
        }
      }

    } else {
      return this.rewards.users
        .find(user => user.uuid === student.uuid)
        .rewards.filter(reward => reward.lesson.id === this.lessonId).map(reward => reward.amount)
        .reduce((sum, cur) => sum + cur, 0);
    }
  }

  dataForStudentsFilter() {
    this.pupils = this.classStudents.map(student => {
      return {
        id: student.uuid,
        text: student.first_name + ' ' + student.last_name
      };
    });
  }

  dataForTaskTypesFilter() {
    const allTaskTypes = this.journalData.map(lesson => lesson.score_types).flat().filter(scoreType => scoreType);
    this.tasks = _.uniqBy(allTaskTypes, 'id').map((scoreType: { id: number, name: string, is_score: boolean }) => {
      if (scoreType) {
        return {
          id: scoreType.id.toString(),
          text: scoreType.name
        };
      }
    });
  }

  userExists(simpleLesson, index) {
    return Object.keys(simpleLesson.users[index].attendance).length;
  }

  dataForGroupEditDrop() {
    this.ansHomeworkEditMarksDrop = [];
    this.groupEditMarksDrop = [];
    this.wholeGroupEditMarksDrop = [];
    const dropdownsData = [
      {
        id: 1,
        text: 'Очистити оцінки та коментарі'
      },
      {
        id: 2,
        text: 'Видалити завдання'
      },
      {
        id: 3,
        text: 'Редагувати завдання'
      }];
    dropdownsData.forEach(item => {
      if (item.id !== 3) {
        this.groupEditMarksDrop.push({
          id: item.id,
          text: item.text
        });
      }
      if (item.id !== 2 && item.id !== 3) {
        this.ansHomeworkEditMarksDrop.push({
          id: item.id,
          text: item.text
        });
      }
      this.wholeGroupEditMarksDrop.push({
        id: item.id,
        text: item.text
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.work = false;

    this.classesService.changePeriodFilter$.next(this.currentMonth);
    window.localStorage.removeItem('achievements-period');
    window.localStorage.removeItem('achievements-students');
    window.localStorage.removeItem('achievements-tasks');
  }

  /* Filtering part */

  onChangePeriod(period) {
    if (window.localStorage.getItem('achievements-period') === period || period === null) {
      return;
    } else if (period && period !== +window.localStorage.getItem('achievements-period')) {
      window.localStorage.setItem('achievements-period', period);
      this.tableLoaded = false;
      this.classesService.changePeriodFilter$.next(period);
    }
  }


  /* Listen for filter changes */
  onFilterByStudents(students) {
    if (window.localStorage.getItem('achievements-students') && !students.length) {
      window.localStorage.removeItem('achievements-students');

      this.studentsFilter = [];
      this.filter();
    } else {
      window.localStorage.setItem('achievements-students', students);

      this.studentsFilter = window.localStorage.getItem('achievements-students').split(',').filter(pupils => pupils);
      this.filter();
    }
  }

  onFilterByTaskTypes(tasks) {
    if (window.localStorage.getItem('achievements-tasks') && !tasks.length) {
      window.localStorage.removeItem('achievements-tasks');
      this.taskTypesFilter = [];
      this.filter();
    } else {
      window.localStorage.setItem('achievements-tasks', tasks);
      this.taskTypesFilter = window.localStorage.getItem('achievements-tasks').split(',').filter(types => types);
      this.filter();
    }
  }

  filter() {
    if (!this.studentsFilter.length && this.taskTypesFilter.length) {
      this.classStudentsFiltered = this.classStudents;
      this.journalDataFiltered = _.cloneDeep(this.journalData);
      this.filterByTasks(this.journalDataFiltered);

    } else if (this.studentsFilter.length && this.taskTypesFilter.length) {

      this.classStudentsFiltered = this.classStudents.filter(user =>
        this.studentsFilter.find(id => {
          return user.uuid === id;
        })
      );
      const journalDataClone = _.cloneDeep(this.journalData);
      this.journalDataFiltered = journalDataClone.map(simpleLesson => {
        this.filterByTasks(journalDataClone);
        const usersUpdated = simpleLesson.users.filter(user =>
          this.studentsFilter.find(id => {
            return user.uuid === id;
          }));
        simpleLesson.users = usersUpdated;
        return simpleLesson;
      });

    } else if (this.studentsFilter.length && !this.taskTypesFilter.length) {
      this.classStudentsFiltered = this.classStudents.filter(user =>
        this.studentsFilter.find(id => {
          return user.uuid === id;
        })
      );
      const journalDataClone = _.cloneDeep(this.journalData);
      this.journalDataFiltered = journalDataClone.map(simpleLesson => {
        const usersUpdated = simpleLesson.users.filter(user =>
          this.studentsFilter.find(id => {
            return user.uuid === id;
          }));
        simpleLesson.users = usersUpdated;
        return simpleLesson;
      });
    } else {
      this.classStudentsFiltered = this.classStudents;
      this.journalDataFiltered = _.cloneDeep(this.journalData);

    }
  }

  filterByTasks(journalDataClone) {
    this.journalDataFiltered = journalDataClone.map(simpleLesson => {
      if (simpleLesson.score_types) {
        const taskTypesUpdated = simpleLesson.score_types.filter(scoreType =>
          this.taskTypesFilter.find(id => {
            return scoreType.id === +id;
          }));
        simpleLesson.users.map(user => {
          const studentScores = user.scores.filter(score =>
            this.taskTypesFilter.find(id => {
              return score.score_type === +id;
            })
          );
          user.scores = studentScores;
        });
        simpleLesson.score_types = taskTypesUpdated;
      }
      return simpleLesson;
    });
  }

  /* asses students */
  updateStudentScore(student, score_type, student_name, student_surname, grade_id, can_add ?, date ?, can_edit ?, student_score ?, journal_id ?, can_open ?, mark?) {
    if (!can_open  || this.isTutorWithoutPermission) {
      return false;
    }

    this.mark_data = {
      journal: journal_id,
      score_type,
      student,
      student_name,
      student_surname,
      grade_id: this.classGradeId,
      date,
      can_add,
      can_edit,
      student_score,
      is_middle_school: this.isMiddleSchool
    };
   // console.log(this.mark_data)
    if (journal_id) {
      this.ngxSmartModalService.resetModalData('student-assessment');
      this.ngxSmartModalService.getModal('student-assessment').setData({
        mark_data: this.mark_data
      });
      this.ngxSmartModalService.getModal('student-assessment').open();
    } else {
      return;
    }
  }

  assesSeveralStudentsAtOnce() {
    const selected_students = Object.keys(_.pickBy(this.checkboxList));
    const lessonDataToAssess = this.journalDataFiltered.filter(lesson => lesson.can_add_scores && lesson.score_types);
    this.mark_data = {
      modal_header: 'Оцінювання',
      student_uuid: selected_students,
      grade_id: this.classGradeId,
      is_middle_school: this.isMiddleSchool,
      can_add: true,
      is_group_mark: true,
      is_group_all_lessons: true,
      lesson_data: lessonDataToAssess,
    };

    this.ngxSmartModalService.resetModalData('student-assessment');
    this.ngxSmartModalService.getModal('student-assessment').setData({
      mark_data: this.mark_data
    });
    this.ngxSmartModalService.getModal('student-assessment').open();
  }

  openMiddleAssesStudentModal(student, score_type, student_name ?, student_surname ?, grade_id ?, can_add ?, date ?, can_edit ?, student_score ?, journal_id ?, can_open ?, lesson?) {
    this.can_add_score = can_add;
    this.updateStudentScore(student, score_type, student_name, student_surname, this.classGradeId, can_add, date, can_edit, student_score, journal_id, can_open, lesson);
  }

  close(open) {
    if (!open) {
      this.isOpen = false;
    }
  }

  /* select students */

  getSelectedCount() {
    this.pageCheckbox = _.keys(this.checkboxList).filter(key => this.checkboxList[key]).length;
    return this.pageCheckbox;
  }

  selectAllList(state) {
    this.classStudentsFiltered.forEach(({uuid}) => {
      this.checkboxList[uuid] = !state;
    });
  }

  onChangeCheckbox(value, studentId) {
    this.checkboxList[studentId] = value;
  }

  groupEditMarks(action, score_type, journal_id) {
    const journal = this.journalDataFiltered.find(journalData => journalData.id === journal_id);
    if (action === 1) {
      const student_uuids = this.classStudentsFiltered.map(student => student.uuid);
      const dataForSend = {
        uuid_list: student_uuids,
        journal: journal_id,
        score_type: score_type.id
      };
      this.classesService.addStudentScore(dataForSend).subscribe(response => {
        this.clearScores(journal, score_type);
      }, error => {
        if (error.error?.detail) {
          alert(error.error?.detail);
        }
      });
    } else if (action === 2) {
      const dataForSend = {
        journal: journal_id,
        score_type: score_type.id
      };
      this.classesService.deleteScoreColumn(dataForSend).subscribe(response => {
        journal.score_types = journal.score_types.filter(scoreType => scoreType.id !== score_type.id);
        this.clearScores(journal, score_type);
      }, error => {
        console.log(error);
      });
    }
  }

  clearScores(journal, score_type) {
    journal.users
      .map(user => user.scores)
      .flat(1)
      .filter(score => score.score_type === score_type.id)
      .forEach(score => {
        score.score = null;
        score.abbr = null;
        score.teacher_comment = null;
      });
  }

  sortedStudents(students, filtered) {
    const filteredStudents = [];
    filtered.forEach(filteredStudent => {
      const find = students.find(student => student.uuid === filteredStudent.uuid);
      filteredStudents.push(find);
    });
    return filteredStudents;
  }

  getStudentScore(studentScores: any, scoreType: any) {
    return [studentScores.find(score => score.score_type === scoreType.id)];
  }

  addScoreType(score_type_id, journal_id) {
    const newScoreType = this.allScoreTypes.score_types.find(item => item.id === score_type_id);
    const selJournal = this.journalDataFiltered.find(journalData => journalData.id === journal_id);
    const isTypeExist = selJournal.score_types.find(score => score.id === score_type_id);

    console.log(isTypeExist, newScoreType);
    if (!isTypeExist && newScoreType) {
      const scoreType = {
        score_type: newScoreType.id,
        journal: journal_id
      };

      this.classesService.createScoreColumn(scoreType).subscribe((results: any) => {
        selJournal.score_types.push(newScoreType);
        if (results.default_data) {
          selJournal.users.forEach(user => {

            if (results.default_data[user.uuid]) {
              user.scores.push(results.default_data[user.uuid]);
            }
          });
        }
      });
    }
  }

  scoresUpdate() {
    this.classesService.updateJournalLessonData$
      .pipe(takeWhile(() => this.work))
      .subscribe(respData => {
        if (respData && (respData.score || respData.teacher_comment || typeof respData === 'number' || respData.file_url) && this.mark_data) {
          /* group assessment */
          if (respData.uuid_list && Array.isArray(respData.uuid_list) && respData.uuid_list.length) {
            this.tableLoaded = false;
            this.loadData();
            return;
          }
          const journal = this.journalDataFiltered.find(journalData => journalData.id === this.mark_data.journal);
          const student = journal.users.find(user => user.uuid === this.mark_data.student.uuid);
          const editedScore = this.mark_data.student_score ? student.scores.find(score => score.id === this.mark_data.student_score.id) : null;

          if (respData.score || respData.teacher_comment || respData.file_url) {

          // console.log(respData)
            /* one student assessment */
            if (respData.score_type && respData.score_type.id && !this.hasSuchScore(student.scores, respData)) {
              respData.score_type = respData.score_type.id;
              /* create new score */
              if (this.isMiddleSchool) {
                student.scores.push(respData);
              } else {
                student.scores.push(respData);
              }
            } else {
              /* edit score */
              if (this.isMiddleSchool) {
                editedScore.abbr = respData ? respData.abbr : null;
                editedScore.score = respData ? respData.score : null;
                editedScore.teacher_comment = respData ? respData.teacher_comment : null;
                editedScore.is_visible_for_student = respData.is_visible_for_student;
                editedScore.is_visible_for_parent = respData.is_visible_for_parent;
              } else {
                editedScore.abbr = respData ? respData.abbr : null;
                editedScore.score = respData ? respData.score : null;
                editedScore.teacher_comment = respData ? respData.teacher_comment : null;
                editedScore.is_visible_for_student = respData.is_visible_for_student;
                editedScore.is_visible_for_parent = respData.is_visible_for_parent;
              }

              editedScore.file_url = respData.file_url;

              this.journalDataFiltered.find(journalData => {
                if (journalData.id === this.mark_data.journal) {
                  return journal;
                }

                return journalData;
              });
              this.changeDetectorRef.detectChanges();
            }
          } else if (typeof respData === 'number') {
            /* delete score */
            const editedScoreIndex = student.scores.findIndex(score => score.id === respData);
            student.scores.splice(editedScoreIndex, 1);
          }
        }
      });
  }

  hasSuchScore(user_scores, respData) {
    if (respData.score_type) {
      return !!user_scores.find(score => score.score_type === respData.score_type.id);
    } else {
      return false;
    }
  }

  changeJournalType(type) {
    if (type === 'scores') {
      window.localStorage.setItem('view-journal-mode', 'scores');
      this.journalType = window.localStorage.getItem('view-journal-mode');
    } else {
      window.localStorage.setItem('view-journal-mode', 'stars');
      this.journalType = window.localStorage.getItem('view-journal-mode');
    }
  }

  getStarsAmount(month, student) {
    const starsAmount = student.rewards.find(reward => reward.month === month.id && reward.lesson.id === +this.lessonId);
    return starsAmount ? starsAmount.amount : '';
  }

  onInputWheel(e) {
    e.preventDefault();
  }

  keyup(e, month, month_index, student) {
    const key = e.key;
    const selectedStudent = this.rewards.users.find(user => user.uuid === student.uuid);
    const studentReward = selectedStudent.rewards.filter(reward => reward.lesson.id === this.lessonId).find(rew => rew.month === month.id);

    const data = {
      month: month.id,
      amount: +e.target.value,
      lesson: +this.lessonId
    };
    if (key !== 'Tab' && key !== 'Shift') {
      if (!studentReward && e.target.value) {
        this.classesService.updateStudentRewards(data, student.uuid).subscribe(() => {
          selectedStudent.rewards.push({
            month: month.id,
            amount: +e.target.value,
            lesson: {
              id: +this.lessonId
            }
          });
        });
      } else if (studentReward) {
        if (studentReward.amount !== +e.target.value) {
          this.classesService.updateStudentRewards(data, student.uuid).subscribe(() => {
            selectedStudent.rewards.forEach(i => {
              if (i.month === month.id) {
                i.amount = +e.target.value;
              }
            });
          });
        }
      } else {
        return;
      }
    }

    if (studentReward) {
      studentReward.amount = +e.target.value;
    } else {
      selectedStudent.rewards.push({
        month: month.id,
        amount: +e.target.value,
        lesson: +this.lessonId
      });
    }
  }

  getTotalRewards() {
    let sum = 0;

    this.rewards.users
      .map(user => user.rewards.filter(reward => reward.lesson.id === this.lessonId))
      .flat(1)
      .map(rew => rew.amount)
      .forEach((amount) => {
        sum += amount;
      });

    return sum;
  }

  toggleAttendance(isChecked, student, journal, can_open) {
    if (!can_open) {
      return false;
    }
    const rez = this.journalDataFiltered.find(journalData => journalData.id === journal).users.find(user => user.uuid === student.uuid);
    rez.attendance.is_absent = !isChecked;

    if (rez.attendance.is_absent) {
      rez.attendance.is_late = false;
    }

    this.visitingEditingId = `${journal}_${student.uuid}`;

    this.classesService.updateStudentAttendance({uuid: student.uuid, journal, is_absent: !isChecked}).subscribe(() => {
      this.visitingEditingId = null;
    }, error => {
      this.visitingEditingId = null;
      rez.attendance.is_absent = isChecked;

      if (rez.attendance.is_absent) {
        rez.attendance.is_late = false;
      }

      if (error.error?.detail) {
        alert(error.error?.detail);
      }
    });
  }

  toggleLateness(isChecked, student, journal) {
    this.visitingEditingId = `${journal}_${student.uuid}`;
    this.classesService.updateStudentAttendance({uuid: student.uuid, journal, is_late: isChecked, is_absent: false}).subscribe(() => {
      this.visitingEditingId = null;
    }, error => {
      this.visitingEditingId = null;
      if (error.error?.detail) {
        alert(error.error?.detail);
      }
    });
  }

  getScoresTypes(types, users) {
    return types.sort((a, b) => {
      return a.priority - b.priority;
    });
  }

  usedScoreTypes(journald) {
    const selJournal = this.journalDataFiltered.find(journalData => journalData.id === journald);

    return _.chain(selJournal.score_types).map('id').map(id => +id).value();
  }

  get isTutorWithoutPermission() {
    return this.rootService.role.isTutor() && !this.rootService.role.hasPermission('journal_edit');
  }
}
