import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BtnComponent} from './components/ui/btn/btn.component';
import {InputFieldComponent} from './components/ui/input-field/input-field.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxYoutubePlayer11Module} from 'ngx-youtube-player-11';
import {NgxMaskModule} from 'ngx-mask';
import {InlineBtnComponent} from './components/ui/inline-btn/inline-btn.component';
import {ModulesNavComponent} from './components/modules-nav/modules-nav.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {CommonModule} from '@angular/common';
import {ImgUploaderComponent} from './components/img-uploader/img-uploader.component';
import {FileUploaderComponent} from './components/file-uploader/file-uploader.component';
import {RoundBtnComponent} from './components/ui/round-btn/round-btn.component';
import {AutosizeModule} from 'ngx-autosize';
import {DropdownComponent} from './components/ui/dropdown/dropdown.component';
import {PreloaderComponent} from './components/ui/preloader/preloader.component';
import {ToggleComponent} from './components/ui/toggle/toggle.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgSelectModule} from '@ng-select/ng-select';
import {CheckboxComponent} from './components/ui/checkbox/checkbox.component';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {NotificationAlertComponent} from './components/ui/notification-alert/notification-alert.component';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {DotsDropdownComponent} from './components/ui/dots-dropdown/dots-dropdown.component';
import {ErrorAlertComponent} from './components/ui/error-alert/error-alert.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {IconBtnComponent} from './components/ui/icon-btn/icon-btn.component';
import {NgPipesModule} from 'ngx-pipes';
import {RadioBtnComponent} from './components/ui/radio-btn/radio-btn.component';
import {ClassGroupPopupComponent} from './components/class-group-popup/class-group-popup.component';
import {MailEditorPopupComponent} from './components/mail-editor-popup/mail-editor-popup.component';
import {ScheduleBoardComponent} from './components/schedule-board/schedule-board.component';
import {NgxSmoothDnDModule} from 'ngx-smooth-dnd';
import {ClassScheduleHeaderComponent} from '../functional-modules/management/_partial/components/class-schedule-header/class-schedule-header.component';
import {JournalLessonComponent} from './components/journal-lesson/journal-lesson.component';
import {StudentAssessmentComponent} from './components/student-assessment/student-assessment.component';
import {FormatDataPipe} from './pipes/format-data.pipe';
import {SortMarksPipe} from './pipes/sort-marks.pipe';
import {StudentMarkPipe} from './pipes/student-mark.pipe';
import {AchievementsComponent} from './components/achievements/achievements.component';
import {LessonContentComponent} from './components/lesson-content/lesson-content.component';
import {YoutubeVideoIdPipe} from './pipes/youtube-video-id.pipe';
import {SearchComponent} from './components/ui/search/search.component';
import {LastNamesSortPipe} from './pipes/lastnames-sort.pipe';
import {PersonalScheduleComponent} from './components/personal-schedule/personal-schedule.component';
import {UpdateClassStudentListComponent} from './components/update-class-student-list/update-class-student-list.component';
import {DeleteClassModalComponent} from './components/delete-class-modal/delete-class-modal.component';
import {EditGeneralClassInfoComponent} from './components/edit-general-class-info/edit-general-class-info.component';
import {StudentAttendanceComponent} from './components/student-attendance/student-attendance.component';
import {DatepickerRangePopupComponent} from './components/ui/datepicker-range-popup/datepicker-range-popup.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {DatepickerI18nComponent} from './components/ui/datepicker-i18n/datepicker-i18n.component';
import {LessonMaterialsRemoveComponent} from './components/lesson-materials-remove/lesson-materials-remove.component';
import {SortDatesPipe} from './pipes/sort-dates.pipe';
import {ClickOutsideDirective} from './directives/click-outside.directive';
import {DeletingPopupComponent} from './components/deleting-popup/deleting-popup.component';
import {CopyLessonMaterialsComponent} from './components/copy-lesson-materials/copy-lesson-materials.component';
import {ModifyLessonMaterialsPopupComponent} from './components/modify-lesson-materials-popup/modify-lesson-materials-popup.component';
import {RemoveZeroDatePipe} from './pipes/remove-zero-date.pipe';
import {HomeworkChatComponent} from './components/homework-chat/homework-chat.component';
import {HomeworksPopupComponent} from './components/homeworks-popup/homeworks-popup.component';
import {ImageRedactorComponent} from './components/image-redactor/image-redactor.component';
import {CreateNewPasswordComponent} from './components/create-new-password/create-new-password.component';
import {EducationScheduleComponent} from './components/education-schedule/education-schedule.component';
import {ServicesPopupComponent} from './components/services-popup/services-popup.component';
import { SlotLessonsPopupComponent } from './components/slot-lessons-popup/slot-lessons-popup.component';
import { PollComponent } from '../functional-modules/polls/_partial/poll/poll.component';
import { VotersComponent } from '../functional-modules/polls/_partial/voters/voters.component';
import { CheckInOutComponent } from './components/check-in-out/check-in-out.component';
import { NotExistingScorePipe } from './pipes/not-existing-score.pipe';

@NgModule({
  declarations: [
    BtnComponent,
    CheckInOutComponent,
    InputFieldComponent,
    InlineBtnComponent,
    ModulesNavComponent,
    TabsComponent,
    ImgUploaderComponent,
    FileUploaderComponent,
    RoundBtnComponent,
    DropdownComponent,
    PreloaderComponent,
    ToggleComponent,
    CheckboxComponent,
    NotificationAlertComponent,
    DotsDropdownComponent,
    ErrorAlertComponent,
    PaginationComponent,
    IconBtnComponent,
    SearchComponent,
    RadioBtnComponent,
    ClassGroupPopupComponent,
    MailEditorPopupComponent,
    ScheduleBoardComponent,
    ClassScheduleHeaderComponent,
    JournalLessonComponent,
    StudentAssessmentComponent,
    FormatDataPipe,
    SortMarksPipe,
    StudentMarkPipe,
    LastNamesSortPipe,
    NotExistingScorePipe,
    AchievementsComponent,
    YoutubeVideoIdPipe,
    AchievementsComponent,
    LessonContentComponent,
    PersonalScheduleComponent,
    UpdateClassStudentListComponent,
    DeleteClassModalComponent,
    EditGeneralClassInfoComponent,
    StudentAttendanceComponent,
    DatepickerRangePopupComponent,
    DatepickerI18nComponent,
    LessonMaterialsRemoveComponent,
    SortDatesPipe,
    ClickOutsideDirective,
    DeletingPopupComponent,
    CopyLessonMaterialsComponent,
    ModifyLessonMaterialsPopupComponent,
    RemoveZeroDatePipe,
    HomeworkChatComponent,
    HomeworksPopupComponent,
    ImageRedactorComponent,
    CreateNewPasswordComponent,
    EducationScheduleComponent,
    ServicesPopupComponent,
    SlotLessonsPopupComponent,
    PollComponent,
    VotersComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    NgxSmoothDnDModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    AutosizeModule,
    PerfectScrollbarModule,
    NgbDatepickerModule,
    NgPipesModule,
    NgxYoutubePlayer11Module.forRoot()
  ],
  exports: [
    ModulesNavComponent,
    BtnComponent,
    InputFieldComponent,
    RouterModule,
    InlineBtnComponent,
    TabsComponent,
    ImgUploaderComponent,
    FileUploaderComponent,
    RoundBtnComponent,
    FormsModule,
    ReactiveFormsModule,
    DropdownComponent,
    PreloaderComponent,
    ToggleComponent,
    CheckboxComponent,
    NotificationAlertComponent,
    NgxSmartModalModule,
    DotsDropdownComponent,
    ErrorAlertComponent,
    SearchComponent,
    PaginationComponent,
    IconBtnComponent,
    NgPipesModule,
    RadioBtnComponent,
    PerfectScrollbarModule,
    ClassGroupPopupComponent,
    MailEditorPopupComponent,
    ScheduleBoardComponent,
    ClassScheduleHeaderComponent,
    JournalLessonComponent,
    StudentAssessmentComponent,
    FormatDataPipe,
    SortMarksPipe,
    StudentMarkPipe,
    LastNamesSortPipe,
    SortDatesPipe,
    RemoveZeroDatePipe,
    NotExistingScorePipe,
    AchievementsComponent,
    LessonContentComponent,
    PersonalScheduleComponent,
    UpdateClassStudentListComponent,
    DeleteClassModalComponent,
    EditGeneralClassInfoComponent,
    StudentAttendanceComponent,
    DatepickerRangePopupComponent,
    LessonMaterialsRemoveComponent,
    DeletingPopupComponent,
    ModifyLessonMaterialsPopupComponent,
    HomeworkChatComponent,
    HomeworksPopupComponent,
    ImageRedactorComponent,
    CreateNewPasswordComponent,
    EducationScheduleComponent,
    ServicesPopupComponent,
    SlotLessonsPopupComponent,
    PollComponent,
    VotersComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
