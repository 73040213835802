import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SectionTabsParams } from '../../interfaces/ui/section-tabs-params';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import SwiperCore from 'swiper';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

  @Input() data: SectionTabsParams[];
  @Input() currTab = 1;
  @Input() links = true;

  @Output() onChangeTab: EventEmitter<any> = new EventEmitter<any>();

  constructor(public router: Router) {
  }

  changeTab(tab) {
    this.currTab = tab;
    this.onChangeTab.emit(tab);
  }

}
