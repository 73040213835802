import {Base} from './base.model';

export class RoleModel extends Base {

  id: string;
  name: string;
  title: string;
  staff_group: string;
  permissions: string[];
  teacher_class_id?: string;
  parent_details?: any;

  constructor(items) {
    super();
    super.loadFields(items);
  }

  getFullName(possession?) {
    switch (this.name) {
      case 'student':
        return !possession ? 'Студент' : 'Студента';
      case 'parent':
        return !possession ? 'Батьки' : 'Батька';
      case 'security':
        return !possession ? 'Охоронець' : 'Охоронеця';
      case 'procurement':
        return !possession ? 'Зав. господарством' : 'Зав. господарством';
      case 'librarian':
        return !possession ? 'Бібліотекар' : 'Бібліотекаря';
      case 'doctor':
        return !possession ? 'Лікар' : 'Лікаря';
      case 'cook':
        return !possession ? 'Кухар' : 'Кухаря';
      case 'teacher_tutor':
        return !possession ? 'Тьютор' : 'Тьютора';
      case 'section_trainer':
        return 'Тренер гуртка';
      case 'section_all_manager':
        return 'Менеджер напряму';
      case 'section_all_director':
        return 'Керівник напряму';
      case 'section_teacher':
        return 'Викладач гуртка';
      case 'section_manager':
        return 'Менеджер гуртка';
      case 'section_admin':
        return 'Адміністратор гуртка';
      case 'teacher':
      case 'teacher_assist':
      case 'teacher_prof':
      case 'teacher_educator':
      case 'teacher_social':
      case 'teacher_logoped':
      case 'teacher_method':
      case 'teacher_psycho':
      case 'teacher_concert':
      case 'teacher_art':
      case 'teacher_music':
      case 'teacher_inst_phys':
      case 'teacher_inst_lab':
      case 'teacher_other':
        return !possession ? 'Вчитель' : 'Вчителя';
      case 'reception':
        return !possession ? 'Рецепція' : 'Рецепціоніста';
      case 'accountant':
        return !possession ? 'Бухгалтер' : 'Бухгалтера';
      case 'secretary':
        return !possession ? 'Секретар' : 'Секретаря';
      case 'assistant_director':
        return !possession ? 'Замісник директора' : 'Замісника директора';
      case 'finance_director':
        return 'Фінансовий керівник';
      case 'director':
      case 'executive_director':
      case 'operating_director':
      case 'academic_director':
        return !possession ? 'Директор' : 'Директора';
      case 'admin':
        return !possession ? 'Адміністратор' : 'Адміністратора';
      case 'psychologist':
        return !possession ? 'Психолог' : 'Психолога';
    }
  }

  getSectionPath() {
    switch (this.name) {
      case 'director':
      case 'finance_director':
      case 'executive_director':
      case 'operating_director':
      case 'academic_director':
      case 'assistant_director':
      case 'admin':
      case 'secretary':
      case 'accountant':
      case 'reception':
      case 'cook':
      case 'doctor':
      case 'psychologist':
      case 'librarian':
      case 'procurement':
      case 'security':
      case 'section_trainer':
      case 'section_all_manager':
      case 'section_all_director':
      case 'section_teacher':
      case 'section_manager':
      case 'section_admin':
        return '/administration';
      case 'teacher':
      case 'teacher_tutor':
      case 'teacher_assist':
      case 'teacher_prof':
      case 'teacher_educator':
      case 'teacher_social':
      case 'teacher_logoped':
      case 'teacher_method':
      case 'teacher_psycho':
      case 'teacher_concert':
      case 'teacher_art':
      case 'teacher_music':
      case 'teacher_inst_phys':
      case 'teacher_inst_lab':
      case 'teacher_other':
        return '/teachers';
      case 'parent':
        return '/parents';
      case 'student':
        return '/pupils';
    }
  }

  getRoleType() {
    switch (this.name) {
      case 'director':
      case 'finance_director':
      case 'executive_director':
      case 'operating_director':
      case 'academic_director':
      case 'assistant_director':
      case 'admin':
      case 'secretary':
      case 'accountant':
      case 'reception':
      case 'cook':
      case 'doctor':
      case 'psychologist':
      case 'librarian':
      case 'procurement':
      case 'security':
      case 'section_trainer':
      case 'section_teacher':
      case 'section_manager':
      case 'section_all_manager':
      case 'section_all_director':
      case 'section_admin':
        return 'administration';
      case 'teacher':
      case 'teacher_tutor':
      case 'teacher_assist':
      case 'teacher_prof':
      case 'teacher_educator':
      case 'teacher_social':
      case 'teacher_logoped':
      case 'teacher_method':
      case 'teacher_psycho':
      case 'teacher_concert':
      case 'teacher_art':
      case 'teacher_music':
      case 'teacher_inst_phys':
      case 'teacher_inst_lab':
      case 'teacher_other':
        return 'teacher';
      case 'parent':
        return 'parent';
      case 'student':
        return 'pupils';
      default:
        return 'other';
    }
  }

  getSectionRedirect(type) {
    if (type === 'administration') {
      const order = ['management', 'my-cabinet'];
      const permissionKeys = {
        management: 'school_view',
      }

      return order.find(section => {

        if (!permissionKeys[section]) {
          return section;
        } else if (this.hasPermission(permissionKeys[section])) {
          return section;
        }

        return null;
      });
    }

    return null;
  }

  hasPermission(permission) {
    return this.permissions.includes(permission);
  }

  isAdministrator() {
    return this.staff_group === '4';
  }

  isAdmin() {
    return this.name === 'admin';
  }

  isDoctor() {
    return this.name === 'doctor';
  }

  isTeacher() {
    return this.staff_group === '1';
  }

  isSectionAllDirector() {
    return this.staff_group === '2';
  }

  isTutor() {
    return this.id === '16';
  }

  isParent() {
    return this.id === '2';
  }

  isWorker() {
    return this.staff_group && this.staff_group !== '1';
  }

  isStudent() {
    return this.id === '1';
  }

  canDeleteLessons() {
    return this.name === 'admin' || this.name === 'director' || this.name === 'assistant_director';
  }

  hasMessenger() {
    return this.isTeacher() || this.isStudent() || this.isParent();
  }

}
