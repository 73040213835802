import {Pipe, PipeTransform} from '@angular/core';
import { IDropdownData } from '../../functional-modules/classes/_partial/interfaces/dropdown-data.interface';

@Pipe({
    name: 'notExistingScore'
})
export class NotExistingScorePipe implements PipeTransform {

    transform(allScores: IDropdownData<any>[], usedScores: any[], usedLength: number): unknown {
        return allScores.filter((scoreItem) => {
            return !usedScores.find(score => score.id === scoreItem.id);
        });
    }
}
