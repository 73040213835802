import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassModel } from '../../models/school-management/class.model';
import { IClassLesson } from '../../../functional-modules/classes/_partial/interfaces/class-lesson.interface';
import { IDropdownData } from '../../../functional-modules/classes/_partial/interfaces/dropdown-data.interface';
import { SectionTabsParams } from '../../interfaces/ui/section-tabs-params';
import { PupilModel } from '../../models/users/pupil.model';
import { ILessonJournal } from '../../interfaces/journal/lesson-journal.interface';
import { ClassesService } from '../../../functional-modules/classes/classes.service';
import { RootService } from '../../services/root.service';
import { switchMap } from 'rxjs/operators';
import { zip } from 'rxjs';
import { AjaxService } from '../../services/ajax.service';

@Component({
    selector: 'check-in-out',
    templateUrl: './check-in-out.component.html',
    styleUrls: ['./check-in-out.component.scss']
})
export class CheckInOutComponent implements OnInit {

    @Input() scheduleId: number;
    @Input() lessonId: number;
    @Input() classId: number;
    @Input() periodId: number;

    @Input() isExpandable: false;
    @Input() simpleClass: {
        class_id?: number;
        class_name: string
        grade: {
            id: number,
            name: string
        }
        group_name?: string
        id: number
        type: string
    };
    @Input() chosenDate: string;
    @Output() expandScheduleDate: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('markPopup') markPopup: ElementRef;

    loaded = false;
    selectedClass: ClassModel;
    selectedLesson: IClassLesson;
    selectedLessonDropdown: IDropdownData<any>;
    lessonsList: IDropdownData<any>[] = [];
    tabs: SectionTabsParams[] = [];
    isError = false;
    pageCheckbox = null;
    classStudentsFiltered: PupilModel[];
    checkboxList = {};
    rewards: {
        month_list: {
            id: number,
            name: string,
        }[],
        users: {
            first_name: string,
            gender: string,
            last_name: string,
            photo: string,
            rewards: {
                month: number,
                amount: number,
                lesson: any
            }[]
            surname: string,
            username: string,
            uuid: string
        }[]
    };
    allScoreTypesDrop: IDropdownData<number>[] = [];
    visitingEditingId;
    mark_data: any;
    journalType = window.localStorage.getItem('view-journal-mode');
    journalDataFiltered: ILessonJournal[];
    classGrade: number;
    classGradeId: number;
    isMiddleSchool: boolean;
    isOpen = false;
    work = true;
    can_add_score: boolean;
    selDate: string;
    groupEditMarksDrop: IDropdownData<any>[] = [];
    ansHomeworkEditMarksDrop: IDropdownData<any>[] = [];
    allScoreTypes: any;
    schedule_id;
    scheduleObject = null;

    constructor(public classesService: ClassesService,
                public ngxSmartModalService: NgxSmartModalService,
                public route: ActivatedRoute,
                private router: Router,
                public rootService: RootService,
                public ajaxService: AjaxService) {
    }

    ngOnInit() {
        this.classesService.getJournalDataByScheduleID(this.scheduleId).subscribe((rez) => {
            this.scheduleObject = rez;
            this.scheduleObject.users = this.scheduleObject.users.map(user => {
                user.attendance = _.find(this.scheduleObject.journal.users, ['uuid', user.uuid]).attendance;

                return user;
            });
            console.log(this.scheduleObject);
            this.loaded = true;
        });
    }

    onChangeLesson(lessonId) {

    }

    selectAllList(state) {
        this.scheduleObject.users.forEach(({uuid}) => {
            this.checkboxList[uuid] = !state;
        });
    }

    onChangeCheckbox(value, studentId) {
        this.checkboxList[studentId] = value;
    }


    getSelectedCount() {
        this.pageCheckbox = _.keys(this.checkboxList).filter(key => this.checkboxList[key]).length;

        return this.pageCheckbox;
    }

    getTotalRewards() {
        let sum = 0;

        this.rewards.users
            .map(user => user.rewards.filter(reward => reward.lesson.id === this.lessonId))
            .flat(1)
            .map(rew => rew.amount)
            .forEach((amount) => {
                sum += amount;
            });

        return sum;
    }

    assesSeveralStudentsAtOnce() {
        const selected_students = Object.keys(_.pickBy(this.checkboxList));
        const lessonDataToAssess = this.journalDataFiltered.filter(lesson => lesson.can_add_scores && lesson.score_types);
        this.mark_data = {
            modal_header: 'Оцінювання',
            student_uuid: selected_students,
            grade_id: this.classGradeId,
            is_middle_school: this.isMiddleSchool,
            can_add: true,
            is_group_mark: true,
            is_group_all_lessons: true,
            lesson_data: lessonDataToAssess,
        };

        this.ngxSmartModalService.resetModalData('student-assessment');
        this.ngxSmartModalService.getModal('student-assessment').setData({
            mark_data: this.mark_data
        });
        this.ngxSmartModalService.getModal('student-assessment').open();
    }

    totalCount(student) {
        const scores = this.scheduleObject
            .users
            .filter(simpleLesson => simpleLesson.score_types)
            .map(simpleLesson => simpleLesson.users).flat()
            .filter(user => user.uuid === student.uuid)
            .filter(studentScores => studentScores.scores.length).flat()
            .map(studentScores => studentScores.scores).flat();

        const withMarks = scores.filter(score => score.score && score.abbr !== 'Н/А');
        const totalMarks = withMarks.filter(score => score.score && score.abbr !== 'Н/А').map(score => score.score).reduce((sum, current) => sum + current, 0);
        const averageMark = (totalMarks / withMarks.length).toFixed(1);

        return isNaN(+averageMark) ? '' : averageMark;
    }

    checkDates(lessonData, score_types) {
        const curDate = new Date();
        const lessonDate = new Date(lessonData);

        if (+this.periodId && score_types) {
            return !(!score_types.length || !score_types);
        } else if (!+this.periodId && score_types) {
            return !(!score_types.length || !score_types);
        } else {
            return lessonDate <= curDate;
        }
    }


    expandDate(scheduleId) {
        this.expandScheduleDate.emit(scheduleId);
    }

    getScoresTypes(types) {
        const practise = _.find(types, ['name', 'Практична робота']);
        const theme = _.find(types, ['name', 'Тематична оцінка']);
        const control = _.find(types, ['name', 'Контрольна робота']);

        return types.sort((a, b) => {
            if (control && theme) {
                if (a.id === control.id && b.id === theme.id) return -1;
                if (a.id === theme.id && b.id === control.id) return 1;
            }

            if (practise && theme) {
                if (a.id === practise.id && b.id === theme.id) return -1;
                if (a.id === theme.id && b.id === practise.id) return 1;
            }

            return a.id - b.id;
        });
    }

    groupEditMarks(action, score_type, journal_id) {
        const journal = this.journalDataFiltered.find(journalData => journalData.id === journal_id);
        if (action === 1) {
            const student_uuids = this.classStudentsFiltered.map(student => student.uuid);
            const dataForSend = {
                uuid_list: student_uuids,
                journal: journal_id,
                score_type: score_type.id
            };
            this.classesService.addStudentScore(dataForSend).subscribe(response => {
                this.clearScores(journal, score_type);
            }, error => {
                if (error.error?.detail) {
                    alert(error.error?.detail);
                }
            });
        } else if (action === 2) {
            const dataForSend = {
                journal: journal_id,
                score_type: score_type.id
            };
            this.classesService.deleteScoreColumn(dataForSend).subscribe(response => {
                journal.score_types = journal.score_types.filter(scoreType => scoreType.id !== score_type.id);
                this.clearScores(journal, score_type);
            }, error => {
                console.log(error);
            });
        }
    }

    clearScores(journal, score_type) {
        journal.users
            .map(user => user.scores)
            .flat(1)
            .filter(score => score.score_type === score_type.id)
            .forEach(score => {
                score.score = null;
                score.abbr = null;
                score.teacher_comment = null;
            });
    }


    /* asses students */
    updateStudentScore(student, score_type, student_name, student_surname, grade_id, can_add ?, date ?, can_edit ?, student_score ?, journal_id ?, can_open ?, mark?) {
        if (!can_open  || this.isTutorWithoutPermission) {
            return false;
        }

        this.mark_data = {
            journal: journal_id,
            score_type,
            student,
            student_name,
            student_surname,
            grade_id: this.classGradeId,
            date,
            can_add,
            can_edit,
            student_score,
            is_middle_school: this.isMiddleSchool
        };
        // console.log(this.mark_data)
        if (journal_id) {
            this.ngxSmartModalService.resetModalData('student-assessment');
            this.ngxSmartModalService.getModal('student-assessment').setData({
                mark_data: this.mark_data
            });
            this.ngxSmartModalService.getModal('student-assessment').open();
        } else {
            return;
        }
    }

    userExists(simpleLesson, index) {
        return Object.keys(simpleLesson.users[index].attendance).length;
    }

    toggleLateness(isChecked, student) {
        this.visitingEditingId = `${this.scheduleObject.journal.id}_${student.uuid}`;
        this.classesService.updateStudentAttendance({uuid: student.uuid, journal: this.scheduleObject.journal.id, is_late: isChecked, is_absent: false}).subscribe(() => {
            this.visitingEditingId = null;
        }, error => {
            this.visitingEditingId = null;
            if (error.error?.detail) {
                alert(error.error?.detail);
            }
        });
    }

    toggleWithoutForm(isChecked, student) {
        this.visitingEditingId = `${this.scheduleObject.journal.id}_${student.uuid}`;
        this.classesService.updateStudentAttendance({uuid: student.uuid, journal: this.scheduleObject.journal.id, without_uniform: isChecked}).subscribe(() => {
            this.visitingEditingId = null;
        }, error => {
            this.visitingEditingId = null;
            if (error.error?.detail) {
                alert(error.error?.detail);
            }
        });
    }


    getStudentScore(studentScores: any, scoreType: any) {
        return [studentScores.find(score => score.score_type === scoreType.id)];
    }

    openMiddleAssesStudentModal(student, score_type, student_name ?, student_surname ?, grade_id ?, can_add ?, date ?, can_edit ?, student_score ?, journal_id ?, can_open ?, lesson?) {
        this.can_add_score = can_add;
        this.updateStudentScore(student, score_type, student_name, student_surname, this.classGradeId, can_add, date, can_edit, student_score, journal_id, can_open, lesson);
    }

    addScoreType(score_type_id, journal_id) {
        const newScoreType = this.allScoreTypes.score_types.find(item => item.id === score_type_id);
        const selJournal = this.journalDataFiltered.find(journalData => journalData.id === journal_id);
        const isTypeExist = selJournal.score_types.find(score => score.id === score_type_id);

        if (!isTypeExist && newScoreType) {
            const scoreType = {
                score_type: newScoreType.id,
                journal: journal_id
            };

            this.classesService.createScoreColumn(scoreType).subscribe((results: any) => {
                selJournal.score_types.push(newScoreType);
                if (results.default_data) {
                    selJournal.users.forEach(user => {

                        if (results.default_data[user.uuid]) {
                            user.scores.push(results.default_data[user.uuid]);
                        }
                    });
                }
            });
        }
    }

    sortedStudents(students, filtered) {
        const filteredStudents = [];
        filtered.forEach(filteredStudent => {
            const find = students.find(student => student.uuid === filteredStudent.uuid);
            filteredStudents.push(find);
        });
        return filteredStudents;
    }

    toggleAttendance(isChecked, student, journal = this.scheduleObject.journal.id) {
        this.visitingEditingId = `${journal}_${student.uuid}`;
        const rez = this.scheduleObject.users.find(user => user.uuid === student.uuid);
        rez.attendance.is_absent = !isChecked;

        if (rez.attendance.is_absent) {
            rez.attendance.is_late = false;
            rez.attendance.without_uniform = false;
        }

        this.classesService.updateStudentAttendance({uuid: student.uuid, journal, is_absent: !isChecked}).subscribe(() => {
            this.visitingEditingId = null;
        }, error => {
            this.visitingEditingId = null;
            rez.attendance.is_absent = isChecked;

            if (rez.attendance.is_absent) {
                rez.attendance.is_late = false;
                rez.attendance.without_uniform = false;
            }

            if (error.error?.detail) {
                alert(error.error?.detail);
            }
        });
    }

    get isTutorWithoutPermission() {
        return this.rootService.role.isTutor() && !this.rootService.role.hasPermission('journal_edit');
    }
}
